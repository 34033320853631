@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500&display=swap')
@import "media"

$accent: #ff3467
$card-shadow: 0 6px 10px -4px rgba(0,0,0,.16)

body
    background: #f5f5f5

.page-wrapper
    padding: 100px 0 0

.light_bg
    background-image: url('../../public/img/logo_bg.svg')
    background-position: center 0%
    background-repeat: no-repeat
    padding-bottom: 60px
    background-size: 120%

.full-page-block
    min-height: calc(100vh - 200px)
    display: flex
    justify-content: center
    align-items: center

.container
    width: 100%
    max-width: 1200px
    margin: 0 auto
    padding: 0 20px

.site-preloader
    position: fixed
    top: 0
    left: 0
    bottom: 0
    right: 0
    background: rgba(255,255,255,.8)
    display: flex
    justify-content: center
    align-items: center
    h4
        color: $accent
        font-size: 44px

.products-list
    padding: 70px 0
    .products-wrapper
        display: grid
        grid-template-columns: repeat(4, 1fr)
        row-gap: 30px
        column-gap: 30px
        .product
            border-radius: 10px
            background: white
            box-shadow: $card-shadow
            text-decoration: none
            position: relative
            overflow: hidden
            display: flex
            flex-direction: column
            align-items: flex-start
            justify-content: space-between
            transition: transform .3s ease
            transform: translateY(0)
            &:hover
                transform: translateY(-10px)
            &:last-of-type
                margin-right: 0
            .details
                flex-grow: 1
                width: 100%
                margin-top: 0px
                padding: 14px
                display: flex
                flex-direction: column
                justify-content: space-between
            .price
                color: $accent
                font-size: 18px
            .description
                font-size: 12px
                line-height: 1.6
                opacity: .6
                margin-bottom: 14px
            .title
                font-weight: 500
                color: black
                // text-align: center
                margin-bottom: 8px
                font-size: 16px
                // margin-bottom: 24px
            .image
                background-color: rgba(0,0,0,.2)
                background-size: cover
                background-repeat: no-repeat
                background-position: center
                width: 100%
                padding-bottom: 100%
        @include media("tablet")
            grid-template-columns: repeat(3, 1fr)
            row-gap: 20px
            column-gap: 20px
        @include media("mobile")
            grid-template-columns: repeat(2, 1fr)


.heading-1
    font-weight: 400
    font-size: 24px
    margin-bottom: 30px
    text-transform: uppercase
    line-height: 1.2


.heading-2
    color: white
    font-size: 24px
    font-weight: 500
    text-transform: uppercase
    margin-bottom: 30px

.breadcrumb
    color: rgba(0,0,0,.4)
    font-size: 12px
    margin-bottom: 20px
    .link
        color: rgba(0,0,0,.4)
        text-decoration: none
    .separator
        margin: 0 6px

.product-info
    .product-title
        font-size: 32px
    .product-info-wrapper
        margin: 40px 0 0
        padding-bottom: 40px
        display: flex
        align-items: flex-start
        justify-content: space-between
        .product-image
            border-radius: 10px
            box-shadow: $card-shadow
            width: 25%
            background-repeat: no-repeat
            background-size: cover
            background-position: center center
            padding-bottom: 25%
        .product-details
            width: 70%
            .description
                font-size: 14px
                line-height: 1.6
            .VideoPlayerWrapper
                margin-bottom: 20px
            .VideoPlayer
                min-height: 400px!important
                height: 400px!important
            .price
                color: $accent
                margin: 24px 0
                margin-left: 30px
                font-size: 20px
            .purchase
                margin-bottom: 20px
                display: flex
                align-items: center
            .btn-link
                width: auto
            .product-features
                padding-top: 20px
                margin: 40px 0 0
                border-top: 1px solid rgba(0,0,0,.2)
                display: flex
                flex-direction: column
                .feature
                    font-size: 14px
                    padding: 8px 0
                    display: flex
                    align-items: center
                    img
                        margin-right: 10px
                        width: 20px
                        height: 20px
    @include media("tablet")
        .product-info-wrapper
            flex-direction: column
            .product-image
                display: none
            .product-details
                width: 100%

.btn-link
    display: block
    cursor: pointer
    color: $accent
    text-align: center
    background-color: transparentize($accent, 1)
    border-radius: 8px
    padding: 12px 28px
    border: 1px solid transparentize($accent, .6)
    width: 100%
    text-decoration: none
    font-weight: 500
    font-size: 14px
    transition-timing-function: ease
    transition-duration: .3s
    transition-property: color border-color background-color opacity
    &:hover
        background-color: $accent
        color: white
        border-color: $accent
    &.highlighted
        background-color: $accent
        color: white
    &.loading, &:disabled
        opacity: .8
        cursor: not-allowed
        color: $accent
        background-color: transparentize($accent, 1)
        border: 1px solid transparentize($accent, .6)
    &.loading
        cursor: progress

.btn-secondary
    border: 1px solid white
    text-decoration: none
    background: transparent
    color: white
    padding: 8px 22px
    font-size: 14px
    font-weight: 500
    border-radius: 30px
    text-shadow: 0 1px 2px rgba(0,0,0,.2)
    &.accent
        text-shadow: none
        color: $accent
        border-color: $accent

.jumbo-section
    .jumbo
        background-image: url("../../public/img/banner-1.png"), linear-gradient(30deg, #b3bdbc, #cfd9d8)
        box-shadow: $card-shadow
        border-radius: 10px
        height: 340px
        padding: 50px
        display: flex
        align-items: center
        .text-wrapper
            color: white
            width: 50%
            font-size: 32px
            line-height: 1.2
            text-shadow: 0 2px 4px rgba(0,0,0,.05)
            font-weight: 600
        @include media("tablet")
            .text-wrapper
                width: 100%
            background-position: right


.project-tiles
    padding: 30px 0 30px
    .container
        display: grid
        grid-template-columns: repeat(4, 1fr)
        grid-template-rows: repeat(2, 1fr)
        grid-column-gap: 30px
        grid-row-gap: 30px
        height: auto
    .tile
        border-radius: 10px
        display: flex
        flex-direction: column
        justify-content: flex-start
        align-items: flex-start
        background-position: right
        background-repeat: no-repeat
        box-shadow: $card-shadow
        height: 320px
        padding: 50px
        background-size: cover
        transition: transform .3s ease
        transform: translateY(0)
        &:hover
            transform: translateY(-10px)
        .title
            margin-bottom: 20px
            font-size: 28px
            color: white
            font-weight: 300
            text-transform: uppercase
        .btn-secondary
            color: white
            border-color: white
            font-size: 12px
            font-weight: 500
            padding: 8px 32px
        &.tile-wide-left
            grid-column: 1 / 3
        &.tile-wide-right
            grid-column: 3 / 5
        &.tile-wide
            grid-column: 1 / 5
            height: 160px
            align-items: center
            justify-content: center
    .tile-1
        background-image: url("../../public/img/banner-2.png"), linear-gradient(-120deg, #0F1417, #1F2228)
    .tile-2
        background-image: url("../../public/img/banner-3.png"), linear-gradient(-120deg, #FB6691, #FA4878)
        padding: 50px 30px
    .tile-3
        background-image: url("../../public/img/banner-4.png"), linear-gradient(-120deg, #2CA4DC, #33ABE0)
        padding: 50px 30px
    .tile-4
        background-image: url("../../public/img/banner-5.png"), linear-gradient(-120deg, #C63081, #F72CC1)
    .tile-5
        background-image: url("../../public/img/banner-6.png"), linear-gradient(-120deg, #D61E24, #D61E24)


    @include media("tablet")
        .container
            grid-template-columns: repeat(2, 1fr)
            grid-template-rows: repeat(3, 1fr) 0px
        .tile
            height: 300px
            padding: 30px!important
            &.tile-wide-left
                grid-column: auto
            &.tile-wide-right
                grid-column: auto
            &.tile-wide
                grid-column: 1 / 3
    @include media("mobile")
        .container
            grid-template-columns: repeat(1, 1fr)
            grid-template-rows: repeat(2, 1fr) 240px
        .tile
            height: 240px
            grid-column: 1 / 5
            &.tile-wide-left
                grid-column: 1 / 5
            &.tile-wide-right
                grid-column: 1 / 5
            &.tile-wide
                grid-column: 1 / 5

.reviews-section
    background-color: $accent
    padding: 40px 0

.social-section
    background-color: $accent
    padding-bottom: 16px
    .container
        display: flex
        justify-content: center
        align-items: center
        padding: 30px 0 30px
    .social-icon-wrapper
        margin: 0 16px
        padding: 8px
        transition: opacity .3s ease
        .social-icon
            height: 24px
        &:hover
            opacity: .8
    .footer-links
        width: 100%
        display: flex
        justify-content: space-between
        align-items: center
        padding-bottom: 30px
        .link
            color: rgba(255,255,255,.8)
            font-size: 14px
            margin: 0 6px
        .language-toggle
            cursor: pointer
            font-size: 14px
        @include media("mobile")
            flex-direction: column
            .link
                margin: 10px 0
    &.light
        background-color: transparent
        .footer-links
            .link
                color: rgba(0,0,0,.4)

.reviews-slider
    padding-bottom: 40px
    .swiper-pagination
        bottom: 0
    .swiper-pagination-bullet-active
        background: white

.bundle-slider
    padding-bottom: 50px

.review-wrapper
    background-color: white
    height: 400px
    overflow: hidden
    border-radius: 10px
    padding: 24px 30px 30px
    box-shadow: 0 6px 12px rgba(104, 17, 39, .3)
    .review-author
        font-size: 14px
        color: $accent
        font-weight: 500
        margin-block-end: 10px
    .review-text
        font-size: 12px
        font-weight: 400
        line-height: 1.6
        color: rgba(0,0,0,1)
    .review-video
        width: 100%
        border-radius: 5px
    .review-image
        width: 100%
        border-radius: 5px


.site-header
    width: 100%
    background: white
    box-shadow: $card-shadow
    position: fixed
    z-index: 10
    left: 0
    top: 0
    .container
        height: 60px
        display: flex
        align-items: center
    .logo-wrapper
        flex-grow: 1
        .logo
            height: 42px
    .nav-link
        margin-left: 50px
        display: flex
        align-items: center
        cursor: pointer
        text-decoration: none
        span
            color: $accent
            line-height: 13px
            font-size: 13px
            font-weight: 500
            text-decoration: underline
            text-decoration-color: transparentize($accent, .6)
        .icon
            margin-right: 12px
            width: 22px
            height: 22px
        &:hover
            span
                text-decoration-color: $accent
    .nav
        display: flex
    @include media("tablet")
        .nav
            display: none

.title-wrapper
    display: flex
    justify-content: space-between
    align-items: center
    .title
        font-size: 28px
        font-weight: 400
        text-transform: uppercase
    .side-link
        color: $accent
        font-size: 14px
        text-decoration: underline
        text-decoration-color: transparentize($accent, .6)
    @include media("mobile")
        flex-direction: column
        .title
            margin-bottom: 10px

.dashboard-products
    padding: 40px 0 0
    display: flex
    justify-content: space-between
    flex-wrap: wrap

.dashboard-product
    width: calc(50% - 12px)
    border-radius: 10px
    background: white
    box-shadow: $card-shadow
    margin-bottom: 24px
    display: flex
    overflow: hidden
    align-items: stretch
    padding: 24px
    .details
        margin-top: 0
        padding-left: 24px
        display: flex
        flex-direction: column
        justify-content: space-between
        .title
            margin-bottom: 12px
            line-height: 1.2
        .description
            flex-grow: 1
            margin-bottom: 14px
            font-size: 12px
    .image
        width: 200px
        height: 200px
        border-radius: 10px
        box-shadow: $card-shadow
        flex-shrink: 0
        background-size: cover
        background-repeat: no-repeat
        background-position: center
    @include media("tablet")
        width: 100%


.dashboard
    .catalog-link-wrapper
        margin: 40px 0 20px
        display: flex
        justify-content: center
        .btn-link
            width: auto
        @include media("tablet")
            .btn-link
                width: 100%

.feedback
    background-color: $accent
    margin-top: 80px
    .container
        padding-top: 40px
        padding-bottom: 40px
        display: flex
        justify-content: center
    .feedback-wrapper
        width: 600px
        .title
            color: white
            font-weight: 600
            text-transform: uppercase
            font-size: 18px
            width: 100%
            text-align: center
            margin-bottom: 10px
        .description
            font-size: 12px
            color: white
            width: 100%
            text-align: center
        .feedback-text
            width: 100%
            height: 160px
            padding: 12px
            font-size: 12px
            resize: none
            border-radius: 10px
            margin: 40px 0 20px
            border: none
        .submit-wrapper
            display: flex
            justify-content: space-between
            align-items: center
            .btn-secondary
                cursor: pointer
                width: 40%
                transition: .2s ease
                &:hover
                    background: white
                    color: $accent
                    text-shadow: none
                &:disabled
                    cursor: not-allowed
                    opacity: .5
                    background: none
                    color: white
                    text-shadow: 0 1px 2px rgba(0,0,0,.2)
            .policy
                color: white
                font-size: 12px
                line-height: 1.4
                width: 50%

.policy-links
    display: flex
    padding: 24px 0 0 
    flex-wrap: wrap
    .policy-link
        background-color: white
        border-radius: 100px
        color: black
        cursor: pointer
        padding: 8px 24px
        text-decoration: none
        box-shadow: $card-shadow
        margin-right: 24px
        margin-bottom: 24px

.auth-form
    margin-bottom: 100px
    width: 500px
    background: white
    border-radius: 10px
    display: flex
    flex-direction: column
    padding: 35px
    box-shadow: $card-shadow
    .auth-title
        text-align: center
        margin-bottom: 30px
        font-weight: 600
        font-size: 22px
        color: $accent
    .auth-links
        padding-top: 10px
        display: flex
        align-items: center
        justify-content: space-evenly
    .auth-link
        font-size: 14px
        font-weight: 500
        text-decoration: underline
        text-decoration-color: transparentize($accent, .5)
        color: $accent
        opacity: .7
        text-align: center
        margin-top: 16px
        &:hover
            opacity: 1
    .auth-input
        margin-bottom: 26px
        height: 44px
        padding: 10px
        font-size: 12px
        border: 1px solid rgba(0,0,0,.15)
        border-radius: 5px
        transition: border-color .3s ease
        &:focus
            border-color: $accent
    .auth-submit
        margin: 10px 0 0
        height: 44px
        background-color: $accent
        color: white
        border-radius: 5px
        cursor: pointer
        border: none
        font-weight: 500
        transition: opacity .3s ease
        &:disabled
            opacity: .6
            cursor: not-allowed
        &.loading
            opacity: .6
            cursor: progress
    .auth-label
        padding-left: 2px
        font-size: 12px
        font-weight: 400
        opacity: .8
        margin-bottom: 6px
    .auth-error
        background-color: #ff6b6b30
        color: #ff6b6b
        border: 1px solid #ff6b6b45
        text-align: center
        border-radius: 5px
        font-size: 14px
        padding: 14px
        margin-bottom: 20px

.form-wrap
    margin-bottom: 100px
    width: 500px
    background: white
    border-radius: 10px
    display: flex
    flex-direction: column
    padding: 35px
    box-shadow: $card-shadow
    .form-submit
        margin: 10px 0 0
        height: 44px
        background-color: $accent
        color: white
        border-radius: 5px
        cursor: pointer
        border: none
        font-weight: 500
        transition: opacity .3s ease
        &:disabled
            opacity: .6
            cursor: not-allowed
        &.loading
            opacity: .6
            cursor: progress
    .form-success
        background-color: #27ae6030
        color: #27ae60
        border: 1px solid #27ae6045
        text-align: center
        border-radius: 5px
        font-size: 14px
        padding: 14px
        margin-bottom: 20px
    .form-error
        background-color: #ff6b6b30
        color: #ff6b6b
        border: 1px solid #ff6b6b45
        text-align: center
        border-radius: 5px
        font-size: 14px
        padding: 14px
        margin-bottom: 20px

.form-input-wrapper
    display: flex
    flex-direction: column
    .form-input
        margin-bottom: 26px
        height: 44px
        padding: 10px
        font-size: 12px
        border: 1px solid rgba(0,0,0,.15)
        border-radius: 5px
        transition: border-color .3s ease
        &:focus
            border-color: $accent
        &:disabled
            opacity: .6
            cursor: not-allowed
    .form-input-label
        padding-left: 2px
        font-size: 12px
        font-weight: 400
        opacity: .8
        margin-bottom: 6px

.spinner-wrapper
    display: flex
    align-items: center
    justify-content: center
    padding: 20px


.loading-spinner
    display: inline-block
    width: 80px
    height: 80px
    &:after
        content: " "
        display: block
        width: 64px
        height: 64px
        margin: 8px
        border-radius: 50%
        border: 6px solid #fff
        border-color: #fff transparent #fff transparent
        animation: spin 1.2s linear infinite
    
@keyframes spin
    0%
        transform: rotate(0deg)
    100%
        transform: rotate(360deg)

.cart
    position: relative
    .icon
        margin-right: 8px!important
    .count
        width: 20px
        height: 20px
        background: $accent
        color: white
        display: flex
        text-align: center
        font-size: 11px
        font-weight: 500
        align-items: center
        justify-content: center
        border-radius: 10px

.cart-wrapper
    display: flex
    padding: 40px 0 150px
    align-items: stretch
    justify-content: space-between
    flex-direction: row
    .order
        width: 46%
    .cart-items
        display: flex
        flex-direction: column
    .details-wrapper
        width: 46%
    .total-price
        border-top: 1px dotted rgba(0,0,0,.2)
        padding-top: 20px
        margin-top: 0px
        margin-bottom: 30px
        font-size: 16px
        .label
            margin-right: 6px
        .price
            font-weight: 500
            color: $accent

    .cart-product
        margin-bottom: 15px
        padding-bottom: 15px
        display: flex
        position: relative
        border-bottom: 1px solid rgba(0,0,0,.15)
        &:last-of-type
            border-bottom: none
            margin-bottom: 0
            padding-bottom: 0
        .info
            padding: 10px
            display: flex
            flex-direction: column
            justify-content: center
            .title
                margin-bottom: 10px
            .price
                color: $accent
        .cart-delete
            position: absolute
            top: 0
            right: 0
            width: 24px
            height: 24px
            display: flex
            align-items: center
            justify-content: center
            cursor: pointer
        .image-wrapper
            margin-right: 10px
            width: 100px
            height: 100px
            border-radius: 6px
            overflow: hidden
            display: flex
            align-items: center
            justify-content: center
            .image
                width: 100%
    @include media("tablet")
        flex-direction: column
        .order, .details-wrapper
            width: 100%
            margin-bottom: 30px
        .details-wrapper
            display: flex
            justify-content: center

.no-products-placeholder, .no-product-placeholder
    height: 300px
    width: 100%
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
    .label
        color: $accent
        font-size: 16px
        font-weight: 500
        text-align: center
        margin-top: 20px
    .icon
        width: 64px
        height: 64px

.player-wrapper
    background: black
    border-radius: 5px
    padding: 5px
    width: 100%
    position: relative
    .player
        width: 100%
        height: 100%

.VideoPlayer
    box-shadow: 0 10px 20px rgba(0,0,0,.3)
    border-radius: 5px
    width: 100%
    min-height: 480px
    background-color: black
    padding: 0
    position: relative
    .novideo
        width: 100%
        height: 100%
        display: flex
        align-items: center
        justify-content: center
        flex-direction: column
        i
            color: $accent
            font-size: 50px
        p
            padding-top: 20px
            font-size: 14px
    .Watermark
        width: 200px
        height: 200px
        cursor: default
        animation: wm 60s linear infinite
        user-select: none
        position: absolute
        font-weight: 600
        text-transform: uppercase
        opacity: .5
        z-index: 6
        letter-spacing: .5px
        font-size: 14px
        text-align: center
        text-shadow: 0 3px 30px black
        p
            margin-bottom: 4px
    video
        margin-bottom: -5px
        width: 100%
        position: relative
        z-index: 5
        
    .Controls
        z-index: 7
        visibility: hidden
        opacity: 0
        transform: translateY(15px)
        transition: .3s ease
        transition-delay: .5s
        position: absolute
        bottom: 15px
        width: 100%
        padding: 0 25px
        display: flex
        justify-content: space-between
        align-items: center
        &::before
            content: ""
            position: absolute
            height: 80px
            bottom: -15px
            z-index: 1
            left: 0
            width: 100%
            background: linear-gradient(to top, rgba(0,0,0,.85), rgba(0,0,0,.55), rgba(0,0,0,0))
        .Duration
            width: 110px
            z-index: 2
            display: flex
            align-items: center
            justify-content: flex-end
            .Delimeter
                padding: 0 4px
                font-size: 11px
                font-weight: 600
                opacity: .7
            .TimeStamp
                text-shadow:  0 2px 3px rgba(0,0,0,.5)
                display: flex
                font-size: 12px
                align-items: center
                span
                    letter-spacing: .5px
                    &:first-of-type
                        padding-right: 1px
                    &:last-of-type
                        padding-left: 1px
        .PlayPause
            z-index: 1
            text-shadow:  0 2px 3px rgba(0,0,0,.5)
            width: 25px
            height: 30px
            cursor: pointer
            display: flex
            align-items: center
            flex-shrink: 0
            justify-content: flex-start
            .player-btn
                position: absolute
                width: 18px
                height: 18px
                transition: .3s ease
            &.paused
                .play
                    opacity: 1
                    transform: scale(1)
                .pause
                    opacity: 0
                    transform: scale(.7)
            &.playing
                .pause
                    opacity: 1
                    transform: scale(1)
                .play
                    opacity: 0
                    transform: scale(.7)
                
        .Progress
            z-index: 1
            margin: 0 15px
            border-radius: 3px
            box-shadow: 0 2px 3px rgba(0,0,0,.15)
            height: 3px
            width: 100%
            position: relative
            background: rgba(255,255,255,.2)
            .LoadBar, .ProgressBar
                background: rgba(255,255,255,.2)
                position: absolute
                z-index: 2
                height: 100%
                transition: width 1s linear
            .ProgressBar
                background: #ff4d79
                z-index: 3
                transition: none
            .Notch
                z-index: 4
                box-shadow: 0 0px 5px rgba(0,0,0,.25)
                width: 10px
                height: 10px
                border-radius: 10px
                background: #ff4d79
                transform: scale(0)
                transition: transform .2s ease
                position: absolute
                top: -4px
                &.seeking
                    transform: scale(1.4)!important
                    
            .RangeSlider
                cursor: pointer
                z-index: 5
                width: 100%
                opacity: .001
                position: absolute
                top: -5px
                &:hover + .Notch
                    transform: scale(1)
    &:hover
        .Controls
            visibility: visible
            opacity: 1
            transform: translateY(0)
            transition-delay: 0s

@keyframes wm
    0%
        left: -10%
        top: -10%
    100%
        left: 110%
        top: 110%

.VideoPlayerWrapper
    width: 100%
    color: white
    overflow: hidden
    border-radius: 5px

.product-locked
    border: 1px solid rgba(0,0,0,.2)
    border-radius: 10px
    padding: 10px 15px
    display: inline-flex
    align-items: center
    font-size: 13px
    line-height: 13px
    .icon
        width: 18px
        height: 18px
        opacity: .5
        margin-right: 10px
    .link
        color: $accent
        font-weight: 500
        text-decoration: underline
        text-decoration-color: transparentize($accent, .6)
        margin-left: 4px

.cart-empty
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    padding: 60px 0
    .empty-label
        font-size: 18px
        font-weight: 500
    .btn-link
        width: 200px
        margin-top: 20px
    
.btn-wrapper
    display: flex
    justify-content: space-between
    .btn-link
        margin-right: 20px
        &:last-of-type
            margin-right: 0px

.order-creating-overlay
    position: fixed
    top: 0
    left: 0
    right: 0
    bottom: 0
    background: white
    z-index: 9999
    color: $accent
    align-items: center
    justify-content: center
    font-size: 24px
    padding: 30px
    font-weight: 500
    display: none
    &.active
        display: flex!important

.bundle-slider-wrapper
    .swiper-pagination 
        height: 30px
        display: flex
        align-items: center
        justify-content: center
        bottom: 0
    .spinner-wrapper
        height: 340px
    .swiper-pagination-bullet-active
        background: $accent
    .loading-spinner::after
        border-color: $accent transparent $accent transparent!important

.bundle-item-wrapper
    width: 100%
    height: 300px
    background-image: linear-gradient(30deg, #818f8e, #cfd9d8)
    border-radius: 8px
    display: flex
    flex-direction: column
    justify-content: space-between
    padding: 30px
    @include media("tablet")
        height: 260px
        padding: 15px
    .bundle-products-wrapper
        display: flex
        .bundle-product
            background-size: cover
            background-position: center
            width: 100px
            height: 100px
            border-radius: 5px
            margin-right: 20px
            transition: .3s ease
            box-shadow: $card-shadow
            &:last-of-type
                margin-right: 0px
            &:hover
                opacity: .9
                transform: scale(1.1)
            @include media("tablet")
                width: 64px
                height: 64px
                margin-right: 10px
                background-position: left

    .bundle-title-wrapper
        display: flex
        align-items: flex-start
        justify-content: space-between
        .bundle-title
            color: white
            font-size: 32px
            line-height: 32px
        .bundle-discount
            padding: 4px 12px
            background: $accent
            font-weight: 500
            font-size: 16px
            color: white
        @include media("tablet")
            .bundle-title
                font-size: 22px
                line-height: 1.3
            .bundle-discount
                font-size: 14px
    .buy-button-wrapper
        display: flex
        align-items: center
        .bundle-price
            display: flex
            align-items: center
        .old-price
            text-decoration: line-through
            color: white
            font-weight: 400
            font-size: 14px
            opacity: .6
        .price
            color: white
            font-size: 16px
            font-weight: 500
            margin: 0 12px
        .buy-button
            display: block
            cursor: pointer
            color: white
            text-align: center
            background-color: transparentize(white, 1)
            border-radius: 8px
            padding: 12px 28px
            border: 1px solid transparentize(white, .6)
            text-decoration: none
            font-weight: 500
            font-size: 14px
            transition-timing-function: ease
            transition-duration: .3s
            transition-property: color border-color background-color opacity
            &:hover
                background-color: white
                color: $accent
                border-color: white
            &.loading
                background-color: transparentize(white, 1)
                color: white
                opacity: .5
                border-color: transparentize(white, .6)
                cursor: not-allowed
        @include media("tablet")
            .bundle-price
                flex-direction: column-reverse
                align-items: flex-start
                justify-content: center
                padding-left: 20px
                .old-price
                    font-size: 12px
                .price
                    margin: 0
                    font-size: 16px
            .buy-button
                

.file-form-wrapper
    padding-bottom: 100px
    .btn-link
        border: 1px solid $accent
        &.loading, &:disabled
            opacity: .5

    .icon
        margin: 0 auto 32px
        width: 38px
        height: 38px
    .page-title
        color: $accent
        font-weight: 600
        font-size: 22px
        text-align: center
        margin-bottom: 14px
    .file-form
        width: 500px
        border-radius: 10px
        padding: 35px
        box-shadow: $card-shadow
        background: white
        .input
            margin-bottom: 26px
            width: 100%
            height: 44px
            padding: 10px
            font-size: 12px
            border: 1px solid rgba(0,0,0,.15)
            border-radius: 5px
            transition: border-color .3s ease
            &:focus
                border-color: $accent

.not-found
    padding: 100px 0
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    .title
        font-weight: 500
        font-size: 48px
        text-align: center
        margin-bottom: 24px
    .link-back
        color: $accent
        text-align: center

.doc
    font-family: sans-serif
    padding: 24px 0 14px
    h4
        margin: 8px 0
    p
        margin-bottom: 14px

.form
    .form-title
        font-size: 18px
        font-weight: 500
        color: $accent
        margin-bottom: 14px
    .tip
        padding: 14px
        background-color: rgba(0,0,0,.04)
        border: 1px solid rgba(0,0,0,.07)
        border-radius: 5px
        color: rgba(0,0,0,.5)
        font-size: 14px
        font-weight: 400
        line-height: 1.5
        margin-bottom: 20px
        transition-property: border-color, color, background-color
        transition-timing-function: ease
        transition-duration: .3s
        &.success
            color: rgba(39, 174, 96, 1)
            background-color: rgba(39, 174, 96, .1)
            border-color: rgba(39, 174, 96, .3)

.back-to-old
    position: fixed
    bottom: 0
    left: 0
    width: 100%
    z-index: 99
    background: $accent
    box-shadow: -6px 0 16px -4px rgba(0,0,0,.15)
    display: none
    .container
        justify-content: space-between
        align-items: center
        padding: 0 15px
        height: 40px
        display: flex
        .title
            color: white
            font-size: 14px
            font-weight: 500
        .close
            background: none
            border: none
            color: white
            cursor: pointer
            font-size: 24px
            line-height: 24px
    &.show
        display: block

.slider-controls
    width: 100%
    display: flex
    align-items: center
    justify-content: center
    position: absolute
    z-index: 10
    bottom: 0
    .slider-slide-btn
        height: 100%
        margin: 0 80px
        width: 32px
        height: 32px
        flex-shrink: 0
        background: none
        border: none
        outline: none
        cursor: pointer
        background-color: #b2b1b1
        border-radius: 100px
        display: flex
        align-items: center
        justify-content: center
        transition: opacity .5s ease
        padding: 10px
        img
            width: 100%
        &:disabled
            opacity: .5
            cursor: default

.features-section
    padding: 30px 0 30px
    .container
        display: flex
        justify-content: space-between
        align-items: stretch
        flex-wrap: wrap
        .feature
            width: 45%
            margin-bottom: 40px
            .title
                font-weight: 500
                font-size: 16px
                line-height: 16px
                margin-bottom: 6px
                display: flex
                align-items: center
                &::before
                    content: ""
                    width: 6px
                    height: 6px
                    margin-right: 6px
                    background-color: $accent
            .desc
                line-height: 1.6
                font-size: 15px
            @include media("tablet")
                width: 100%


.lds-roller
  display: inline-block
  position: relative
  width: 80px
  height: 80px
  div
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite
    transform-origin: 40px 40px
    &::after
        content: ""
        display: block
        position: absolute
        width: 7px
        height: 7px
        border-radius: 50%
        background: $accent
        margin: -4px 0 0 -4px
    &:nth-child(1)
        animation-delay: -0.036s
        &::after
            top: 63px
            left: 63px
    &:nth-child(2)
        animation-delay: -0.072s
        &::after
            top: 68px
            left: 56px
    &:nth-child(3)
        animation-delay: -0.108s
        &::after
            top: 71px
            left: 48px
    &:nth-child(4)
        animation-delay: -0.144s
        &::after
            top: 72px
            left: 40px
    &:nth-child(5)
        animation-delay: -0.18s
        &::after
            top: 71px
            left: 32px
    &:nth-child(6)
        animation-delay: -0.216s
        &::after
            top: 68px
            left: 24px
    &:nth-child(7)
        animation-delay: -0.252s
        &::after
            top: 63px
            left: 17px
    &:nth-child(8)
        animation-delay: -0.288s
        &::after
            top: 56px
            left: 12px
    
@keyframes lds-roller
    0%
        transform: rotate(0deg)
    100%
        transform: rotate(360deg)

.product-desc
    padding: 20px 0 15px
    .label
        font-weight: 500
        font-size: 18px
        margin-bottom: 14px
    .desc
        line-height: 1.7
        font-size: 15px
        color: rgba(0,0,0,.6)
        

.cart-form
    width: 100%

.mobile-menu-toggle
    flex-shrink: 0
    display: none
    flex-direction: column
    justify-content: space-evenly
    align-items: center
    width: 32px
    height: 32px
    padding: 4px
    margin-left: 32px
    cursor: pointer
    .line
        height: 2px
        width: 22px
        border-radius: 5px
        background: $accent
    @include media("tablet")
        display: flex

.mobile-menu-wrapper
    position: fixed
    z-index: 9
    left: 0
    right: 0
    top: 0
    bottom: 0
    display: none
    background-color: white
    align-items: center
    justify-content: center
    visibility: hidden
    .nav
        display: flex
    &.opened
        display: flex
    @include  media("tablet")
        visibility: visible
        .nav
            padding-top: 40px
            flex-direction: column
            align-items: center
            justify-content: center
        
.mobile-menu
    position: fixed
    top: 60px
    left: 0
    height: auto
    z-index: 9
    width: 100%
    background: white
    .nav-link
        display: flex
        margin: 20px 0
        align-items: center
        cursor: pointer
        text-decoration: none
        span
            color: $accent
            line-height: 20px
            font-size: 20px
            font-weight: 500
            text-decoration: underline
            text-decoration-color: transparentize($accent, .6)
        &:hover
            span
                text-decoration-color: $accent

.bundle-tip
    padding: 24px 0 0
    font-size: 14px
    color: rgba(0,0,0,.5)
    text-align: center

.status-page
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
    padding-bottom: 100px
    .text
        font-weight: 500
        color: #6ab04c
        font-size: 18px
        margin-bottom: 24px
    &.fail-page
        .text
            color: #f0932b
    .icon
        width: 52px
        margin-bottom: 16px

.ghost-btn
    margin-top: 10px
    color: $accent
    font-size: 16px
    opacity: .8

.change-phone
    font-size: 14px
    margin-bottom: 8px
    color: rgba(0,0,0,.5)
    cursor: pointer
    text-align: center
    padding-bottom: 1px

.language-toggle
    padding: 14px
    margin: 20px
    font-size: 14px

.notice-block
    border-left: 2px solid $accent
    padding: 14px
    padding-left: 14px
    width: 100%
    max-width: 500px
    margin-bottom: 30px

.bundle-popup-wrapper
    background: rgba(0,0,0,.5)
    position: fixed
    z-index: 999
    top: 0
    left: 0
    right: 0
    bottom: 0
    display: flex
    justify-content: center
    align-items: flex-start
    padding: 200px 10px 10px
    .bundle-popup
        background: white
        box-shadow: $card-shadow
        border-radius: 10px
        padding: 15px 30px 30px
        width: 500px
        max-width: 100%
        display: flex
        flex-direction: column
        .close-wrapper
            width: 100%
            display: flex
            justify-content: space-between
            align-items: center
            margin-bottom: 14px
            .popup-title
                font-size: 14px
                font-weight: 500
            .close
                font-size: 24px
                padding: 4px
                cursor: pointer

.profile-wrapper
    padding: 40px 0 40px
    display: flex
    align-items: stretch
    justify-content: space-between
    .profile-stats
        width: 48%
        display: flex
        flex-direction: column
        justify-content: center
    .password-change-wrapper
        width: 48%
        .password-change
            margin-bottom: 0
            width: 100%
    @include  media("tablet")
        flex-direction: column
        .profile-stats, .password-change-wrapper
            width: 100%
    

.profile-stat
    display: flex
    flex-direction: column
    margin-bottom: 40px
    .label
        font-size: 16px
        line-height: 1
        font-weight: 500
        margin-bottom: 10px
    .stat
        opacity: .8
        font-size: 16px
        line-height: 1
        font-weight: 400

.dashboard-container
    min-height: calc(100vh - 220px)
    padding-bottom: 40px

.VideoPlayer
    @include media("mobile")
        min-height: 0