*
    font-family: 'Montserrat', sans-serif

*, *::before, *::after 
    box-sizing: border-box

ul[class], ol[class] 
    padding: 0


/* Remove default margin */
body, h1, h2, h3, h4, p, ul[class], ol[class], li, figure, figcaption, blockquote, dl, dd
    margin: 0


/* Set core body defaults */
body 
    min-height: 100vh
    scroll-behavior: smooth
    text-rendering: optimizeSpeed
    line-height: 1.5


/* Remove list styles on ul, ol elements with a class attribute */
ul[class], ol[class] 
    list-style: none

/* A elements that don't have a class get default styles */
a:not([class]) 
    text-decoration-skip-ink: auto

/* Make images easier to work with */
img 
    max-width: 100%
    display: block


/* Natural flow and rhythm in articles by default */
article > * + * 
    margin-top: 1em

/* Inherit fonts for inputs and buttons */
input, button, textarea, select 
    font: inherit
    outline: none

$accent: #ffa502
// #FF214F

$size-mobile: 700px
$size-tablet: 900px
$size-desktop: 1200px
$size-desktop-xl: 1800px

$sizes: ("mobile": $size-mobile, "tablet": $size-tablet, "desktop": $size-desktop, "desktop-xl": $size-desktop-xl)

@mixin media($list, $down: true)
    @if length($list) == 2
        @media (min-width: if(type-of(nth($list, 1)) == "string", map-get($sizes, nth($list, 1)), nth($list, 1))) and (max-width: if(type-of(nth($list, 2)) == "string", map-get($sizes, nth($list, 2)), nth($list, 2)))
            @content
    @else 
        @if $down
            @media (max-width: if(type-of(nth($list, 1)) == "string", map-get($sizes, nth($list, 1)), nth($list, 1)))
                @content
        @else
            @media (min-width: if(type-of(nth($list, 1)) == "string", map-get($sizes, nth($list, 1)), nth($list, 1)))
                @content
